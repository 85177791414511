<template>
  <v-container class="fill-height contacte-container pt-md-10">
    <v-row class="text-center mb-2" align="start" justify="center">
      <v-col cols="12" md="8" class="mb-2" align-self="start">
        <h2 class="header font-weight-bold my-2 white--text">
          CONTACTA AMB NOSALTRES
        </h2>

        <v-spacer></v-spacer>

        <p class="subheading font-weight-regular mb-5 dark white--text">
          Si us plau, omple el formulari a continuació i en breu ens posarem en
          contacte.
        </p>

        <v-alert type="error" v-if="showError" class="my-2 mx-auto">
          Woops! Sembla que hi ha algún problema per enviar el teu missatge.
          {{ error }}
        </v-alert>
        <v-alert type="success" v-if="showSuccess" class="my-2 mx-auto">
          S'ha enviat el teu missatge correctament! En breu ens posarem en
          contacte. Gràcies!
        </v-alert>
      </v-col>
    </v-row>

    <v-spacer></v-spacer>

    <v-row align="start" justify="center">
      <v-col md="5">
        <v-card class="mx-auto pa-5">
          <v-form ref="form" v-model="valid" @submit.prevent="submitContact">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="orange"
            ></v-progress-linear>
            <v-text-field
              v-model.trim="contactForm.name"
              :counter="80"
              :rules="nameRules"
              label="Nom"
              required
            ></v-text-field>

            <v-text-field
              v-model.trim="contactForm.email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>

            <v-text-field
              v-model.trim="contactForm.phone"
              :rules="phoneRules"
              label="Telèfon"
            >
            </v-text-field>

            <v-select
              v-model="contactForm.subject"
              :items="subjectItems"
              :rules="[v => !!v || 'L\'assumpte és requerit']"
              label="Assumpte"
              required
              class="mb-2"
            ></v-select>

            <v-textarea
              outlined
              name="message"
              label="Missatge"
              v-model="contactForm.message"
              :rules="[v => !!v || 'El missatge és requerit']"
              class="mb-0"
              required
            ></v-textarea>

            <v-checkbox
              v-model="checkboxPolitica"
              :rules="[
                v => !!v || 'És necessari que ho acceptis per a continuar!'
              ]"
              label="He llegit i accepto la política de privacitat"
              required
              class="mt-0 mb-2"
            >
              <template v-slot:label>
                He llegit i accepto la
                <v-btn
                  text
                  small
                  color="blue darken-1"
                  @click.stop="openLegalTextsModal()"
                  class="ml-1"
                >
                  Política de Privacitat
                </v-btn>
              </template>
            </v-checkbox>

            <v-row justify="end" class="my-2">
              <v-btn outlined color="error" class="mr-4" @click="reset">
                Esborrar
              </v-btn>

              <v-btn
                :disabled="valid === false"
                color="success"
                class="mr-4"
                @click="submitContact()"
              >
                Enviar
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card dense class="mx-auto px-2">
          <v-card-subtitle class="title primary--text mb-0 pb-0">
            OFICINA CENTRAL
          </v-card-subtitle>
          <v-list two-line class="mt-0">
            <v-list-item link @click="phoneTo">
              <v-list-item-icon>
                <v-icon color="gray">
                  mdi-phone
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                  >977 660 821</v-list-item-title
                >
                <v-list-item-subtitle>Atenció al client</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              link
              @click="mailto('comercial@autocarsdelpenedes.com')"
            >
              <v-list-item-icon>
                <v-icon color="gray">
                  mdi-email
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  >comercial@autocarsdelpenedes.com</v-list-item-title
                >
                <v-list-item-subtitle>Comercial</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="mailto('pasbus1@pndssm.org')">
              <v-list-item-icon>
                <v-icon color="gray">
                  mdi-email
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>pasbus1@pndssm.org</v-list-item-title>
                <v-list-item-subtitle>Pasbus</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="mapsTo">
              <v-list-item-icon>
                <v-icon color="gray">
                  mdi-map-marker
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle>Ubicació</v-list-item-subtitle>
                <v-list-item-title
                  >C/Tarragonès, 34 <br />
                  Polígon Industrial Moli d'en Serra <br />
                  43710 Santa Oliva (Tarragona)</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card dense class="mx-auto px-2 my-2">
          <v-card-subtitle class="title primary--text mb-0 pb-0">
            ESPAIBUS
          </v-card-subtitle>
          <v-list two-line class="mt-0">
            <v-list-item link @click="phoneTo">
              <v-list-item-icon>
                <v-icon color="gray">
                  mdi-phone
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                  >938 171 275</v-list-item-title
                >
                <v-list-item-subtitle>Atenció al client</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click="mailto('pasbus.vilafranca@pndssm.org')">
              <v-list-item-icon>
                <v-icon color="gray">
                  mdi-email
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  >pasbus.vilafranca@pndssm.org</v-list-item-title
                >
                <v-list-item-subtitle>Pasbus</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click="mapsTo">
              <v-list-item-icon>
                <v-icon color="gray">
                  mdi-map-marker
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle>Ubicació</v-list-item-subtitle>
                <v-list-item-title
                  >c/ Pere el Gran, 26 <br />
                  08720 Vilafranca del Penedès<br />
                  (Barcelona).
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card dense class="mx-auto px-2">
          <v-card-subtitle class="title primary--text mb-0 pb-0">
            Horari d'atenció al client
          </v-card-subtitle>
          <v-card-text class="body-1">
            <!-- <strong>Horari d'atenció al client</strong><br /> -->
            De dilluns a divendres de 8 a 20h. <br />
            Dissabtes de 10 a 13 h.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="50"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Contact from "@/api/contact";

export default {
  name: "Contactar",

  data: () => ({
    valid: false,
    loading: false,
    showError: false,
    error: null,
    showSuccess: false,
    nameRules: [
      v => !!v || "El Nom es requerit",
      v => (v && v.length <= 80) || "El nom no pot tenir més de 80 caràcters"
    ],
    emailRules: [
      v => !!v || "El e-mail es requerit",
      v => /.+@.+\..+/.test(v) || "Si us plau posa un e-mail vàlid"
    ],
    phoneRules: [
      // v => !!v || "El Nom es requerit",
      v =>
        (v && v.length <= 14) || "El telèfon no pot tenir més de 14 caràcters"
    ],
    subjectItems: [
      "Serveis regular",
      "Serveis a particulars",
      "Serveis a empreses",
      "Incidències",
      "Administració",
      "Recursos Humans",
      "Altres"
    ],
    checkboxPolitica: false,
    user: {
      token: "",
      userId: 0
    },
    contactForm: {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: ""
    }
  }),

  // computed: {
  //   getUserInfo() {
  //     if (!this.$store.getters.loggedIn) {
  //       return null;
  //     } else {
  //       return this.$store.getters.getUserInfo;
  //     }
  //   }
  // },

  created() {
    if (this.$store.getters.loggedIn) {
      this.user = this.$store.getters.getUserInfo;
    }
  },

  mounted() {
    if (this.$store.getters.loggedIn) {
      this.setUser();
    }
    this.valid = false;
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    setUser() {
      this.contactForm.name = this.user.name + " " + this.user.surnames;
      this.contactForm.email = this.user.email;
      this.contactForm.phone = this.user.phone;
    },
    async submitContact() {
      this.loading = true;
      let payload = {
        token: this.$store.state.token || "",
        user_id: this.user.userId || 0,
        subject: this.contactForm.subject,
        name: this.contactForm.name,
        phone: this.contactForm.phone,
        email: this.contactForm.email,
        message: this.contactForm.message
      };

      await Contact.sendContact(payload)
        .then(response => {
          if (response.data) {
            this.showSuccess = true;
          } else {
            this.showError = true;
          }
          this.loading = false;
          this.reset();
        })
        .catch(error => {
          this.error = error;
          this.loading = false;
          this.showError = true;
        });
    },
    phoneTo() {
      window.location.href = "tel:+34977660821";
    },
    mailto(email) {
      window.location.href = "mailto:" + email;
    },
    mapsTo() {
      window.open("https://goo.gl/maps/oMhr32xrKAKXD3Vt8", "_blank");
    },
    openLegalTextsModal() {
      this.$store.dispatch("actionLegalTextsModal", true);
    }
  }
};
</script>
